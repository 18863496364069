<template>
  <SignInForm
    module="page"
    :image="image"
    content-classes="px-small md:px-0"
    data-testid="signin-page"
  />
</template>

<script setup lang="ts">
/** components */
import SignInForm from '~/components/SignInForm.vue'

/** composables */
import { useScreenSize } from '~/composables/useScreenSize'

/** constants */
import { LOGIN_IMAGES } from '~/src/constants/auth'

/** utils */
import { random } from '~/utils/random'
import { cloudinaryBuildRoute } from '~/src/utils/cloudinary'

/** refs */
const loading = ref<boolean>(true)

/** app */
const { $bmAuth } = useNuxtApp()
const $router = useRouter()
const { getSeoInfo } = useSeo()
const seo = getSeoInfo({
  title: 'Login',
})
const { isNotDesktop } = useScreenSize()

/** computed */

const image = computed(() => {
  const modifiers = isNotDesktop.value ? ['w_500', 'h_500'] : ['w_700']
  if (loading.value) return ''

  const rand = random()
  const size = LOGIN_IMAGES.length

  const randRound = Math.floor(rand * size)

  return cloudinaryBuildRoute(LOGIN_IMAGES[randRound], modifiers)
})

useHead(seo)
onMounted(() => {
  if ($bmAuth.isAuthenticated()) {
    return $router.push('/')
  }

  loading.value = false
})
</script>
